import axios from "axios";

/**
 * 获取已支付订单记录
 * @param params
 * @param page
 * @param pageSize
 * @returns {Promise<AxiosResponse<T>>}
 */
export const paidList = (params, page, pageSize) => {
  return axios.get("/payOrder/paidList", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

/**
 * 未支付
 * @param params
 * @param page
 * @param pageSize
 * @returns {Promise<AxiosResponse<T>>}
 */
export const oweList = (params, page, pageSize) => {
  return axios.get("/payOrder/oweList", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

/**
 * 支付订单详情-待支付-错误免单
 * @param parkingRecordId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const oweDetail = id => {
  return axios.get(`/payOrder/${id}/oweDetail`);
};

/**
 * 已支付订单详情-退款用
 * @param payOrderId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const paidDetail = payOrderId => {
  return axios.get(`/payOrder/${payOrderId}/paidDetail`);
};

/**
 * 错误免单列表
 * @param params
 * @param page
 * @param pageSize
 * @returns {Promise<AxiosResponse<T>>}
 */
export const feeList = (params, page, pageSize) => {
  return axios.get("/payOrder/feeList", {
    params: {
      ...params,
      page,
      pageSize,
    },
  });
};

/**
 *
 * @param payOrderId
 * @param vo {remark: ""免单原因}
 * @returns {Promise<AxiosResponse<T>>}
 */
export const updateFeePayOrder = (parkingRecordId, vo) => {
  return axios.put(`/payOrder/${parkingRecordId}/updateFeePayOrder`, vo);
};

/**
 * 审核停车记录
 * @param parkingRecordId
 * @returns {Promise<AxiosResponse<any>>}
 */
export const auditParkingRecord =(parkingRecordId) => {
  return axios.put(`/payOrder/${parkingRecordId}/audit`);
};


/**
 * 免单详情
 * @param payOrderId
 * @returns {Promise<AxiosResponse<T>>}
 */
export const feeDetail = payOrderId => {
  return axios.get(`/payOrder/${payOrderId}/feeDetail`);
};
