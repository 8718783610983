<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="添加退款申请"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
        >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="订单号" v-bind="validateInfos.payOrderId">
        <a-input-search
          v-model:value="refundVo.payOrderId"
          placeholder="请输入支付订单号"
          enter-button
          @search="onSearch"
        >
          <template #enterButton>
            <a-button>查询订单详情</a-button>
          </template>
        </a-input-search>
      </a-form-item>
      <a-form-item label="退款金额" v-bind="validateInfos.refundAmount">
        <a-input
          v-model:value="refundVo.refundAmount"
          placeholder="退款金额不能大于订单金额"
        >
        </a-input>
      </a-form-item>
      <a-form-item label="备注" v-bind="validateInfos.reason">
        <a-textarea :row="2" v-model:value="refundVo.reason"></a-textarea>
      </a-form-item>
    </a-form>

    <div v-if="isRefundVo">
      <a-descriptions bordered size="small" :column="1">
        <a-descriptions-item label="订单号">{{
          refundRecordVo.payOrderId
        }}</a-descriptions-item>
        <a-descriptions-item label="支付状态">
          <a-tag v-if="refundRecordVo.payState == 0" color="red">待支付</a-tag>
          <a-tag v-if="refundRecordVo.payState == 2" color="success"
            >支付完成</a-tag
          >
          <a-tag v-if="refundRecordVo.payState == 3" color="orange"
            >待结算</a-tag
          >
        </a-descriptions-item>
        <a-descriptions-item label="实付金额">{{
          refundRecordVo.paidAmount
        }}</a-descriptions-item>
        <a-descriptions-item label="支付时间">{{
          refundRecordVo.payTime
        }}</a-descriptions-item>
        <a-descriptions-item label="退款人昵称">{{
          refundRecordVo.nickName
        }}</a-descriptions-item>
        <a-descriptions-item label="退款人电话">{{
          refundRecordVo.phone
        }}</a-descriptions-item>
      </a-descriptions>
    </div>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useRefundRecord from "@/hooks/Info/useRefundRecord";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { paidDetail } from "../../../../api/info/payRecordApi";
import { addRefund } from "../../../../api/info/refundRecordApi";

const useForm = Form.useForm;
export default {
  name: "Add",
  props: ["modalRef"],
  emits: ["close"],
  setup(pros, { emit }) {
    const disabled = ref(false);
    const { refundRecordVo, refundVo, money, moneyZ } = useRefundRecord();
    const { refundVoRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(refundVo, refundVoRules);
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const isRefundVo = ref(false);
    const onSearch = async val => {
      if (!val) {
        showTipMessage("请填写订单号查询", "error");
        return;
      }
      try {
        let { data } = await paidDetail(val);
        refundRecordVo.payOrderId = data.payOrderId;
        refundRecordVo.payState = data.payState;
        refundRecordVo.shouldPayAmount = money(data.shouldPayAmount);
        refundRecordVo.paidAmount = money(data.paidAmount);
        refundRecordVo.payTime = data.payTime;
        refundRecordVo.phone = data.phone;
        if (data != null) {
          isRefundVo.value = true;
        }
      } catch (e) {
        showErr(e);
      }
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("添加新的退款申请?");
        if (action) {
          disabled.value = true;
          await showLoading();

          let obj = {
            payOrderId: refundVo.payOrderId,
            refundAmount: moneyZ(refundVo.refundAmount),
            reason: refundVo.reason,
          };
          try {
            await addRefund(obj);
            await closeLoading();
            await showTipMessage("添加成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {});
    return {
      ...toRefs(pros),
      disabled,
      visible,
      isRefundVo,
      afterClose,
      refundRecordVo,
      refundVo,
      validateInfos,
      money,
      moneyZ,
      doSubmit,
      onSearch,
    };
  },
};
</script>

<style scoped></style>
